<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="58.707" height="59.741" viewBox="0 0 58.707 59.741">
        <path id="_--icon_mundo" data-name="--icon_mundo" d="M29.252,0A28.41,28.41,0,0,1,50.032,8.828a29.859,29.859,0,0,1,0,42.085,29.225,29.225,0,0,1-20.779,8.828A28.888,28.888,0,0,1,8.675,50.913,29.544,29.544,0,0,1,0,29.768,29.066,29.066,0,0,1,8.675,8.828,28.082,28.082,0,0,1,29.252,0ZM35.91,3.9a36.383,36.383,0,0,1,6.859,11.086,35.494,35.494,0,0,0,6.254-3.079A25.814,25.814,0,0,0,35.91,3.9Zm14.929,10.47a44.284,44.284,0,0,1-7.061,3.285,40.965,40.965,0,0,1,1.816,12.112H55.68A26.273,26.273,0,0,0,50.839,14.371Zm4.64,18.271H45.593a46.771,46.771,0,0,1-2.623,11.7,36.5,36.5,0,0,1,6.456,2.874A26.026,26.026,0,0,0,55.479,32.642Zm-8.07,16.834a33.693,33.693,0,0,0-5.649-2.464,37.1,37.1,0,0,1-5.85,8.828A25.629,25.629,0,0,0,47.409,49.476ZM30.866,56.661a34.853,34.853,0,0,0,7.666-10.47,31.41,31.41,0,0,0-7.666-1.026Zm-3.026-11.5a34.476,34.476,0,0,0-7.868,1.026,39.407,39.407,0,0,0,7.868,10.47Zm3.026-3.079a42.218,42.218,0,0,1,8.877,1.437,34.007,34.007,0,0,0,2.421-10.881h-11.3ZM27.84,32.642h-11.5a49.918,49.918,0,0,0,2.421,10.881,46.405,46.405,0,0,1,9.078-1.437Zm3.026-2.874h11.5a40.9,40.9,0,0,0-1.614-11.086,41.079,41.079,0,0,1-9.885,1.437ZM27.84,20.119a39.675,39.675,0,0,1-9.885-1.437,34.314,34.314,0,0,0-1.614,11.086h11.5Zm3.026-2.874a46.056,46.056,0,0,0,8.877-1.232A35.936,35.936,0,0,0,30.866,3.079ZM27.84,3.079a35.936,35.936,0,0,0-8.877,12.934,42.091,42.091,0,0,0,8.877,1.232Zm-11.9,11.907A32.863,32.863,0,0,1,22.8,3.9,25.814,25.814,0,0,0,9.684,11.907,29.726,29.726,0,0,0,15.938,14.986ZM13.113,29.768a40.965,40.965,0,0,1,1.816-12.112,37.355,37.355,0,0,1-7.061-3.285,26.945,26.945,0,0,0-5.044,15.4Zm2.623,14.576a39.328,39.328,0,0,1-2.623-11.7H3.026A26.456,26.456,0,0,0,9.28,47.218,36.5,36.5,0,0,1,15.736,44.344ZM22.8,55.84a31.875,31.875,0,0,1-5.85-8.828A27.786,27.786,0,0,0,11.3,49.476,24.615,24.615,0,0,0,22.8,55.84Z" :fill="fill"/>
    </svg>
</template>

<script>
export default {
    name: 'IconGlobe',
    props: {
        fill: {
            type: String,
            default: '#00305a'
        }
    }
}
</script>
