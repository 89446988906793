<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="44.114" height="61.081" viewBox="0 0 44.114 61.081">
        <g id="Grupo_3207" data-name="Grupo 3207" transform="translate(-1849 -2168)">
            <path id="Caminho_2161" data-name="Caminho 2161" d="M59.631,17.393a5.094,5.094,0,0,1,5.09,5.09V66.6a5.094,5.094,0,0,1-5.09,5.09H32.483a5.094,5.094,0,0,1-5.09-5.09V22.483a5.094,5.094,0,0,1,5.09-5.09H59.631m0-3.393H32.483A8.487,8.487,0,0,0,24,22.483V66.6a8.487,8.487,0,0,0,8.483,8.483H59.631A8.487,8.487,0,0,0,68.114,66.6V22.483A8.487,8.487,0,0,0,59.631,14Z" transform="translate(1825 2154)" :fill="fill"/>
            <path id="Caminho_2162" data-name="Caminho 2162" d="M58.3,25.393a.851.851,0,0,1,.848.848v6.787a.851.851,0,0,1-.848.848H36.242a.851.851,0,0,1-.848-.848V26.242a.851.851,0,0,1,.848-.848H58.3M58.3,22H36.242A4.243,4.243,0,0,0,32,26.242v6.787a4.243,4.243,0,0,0,4.242,4.242H58.3a4.243,4.243,0,0,0,4.242-4.242V26.242A4.243,4.243,0,0,0,58.3,22Z" transform="translate(1823.787 2152.787)" :fill="fill"/>
            <path id="Caminho_2163" data-name="Caminho 2163" d="M36.242,47.393a.848.848,0,1,1-.848.848.851.851,0,0,1,.848-.848m0-3.393a4.242,4.242,0,1,0,4.242,4.242A4.243,4.243,0,0,0,36.242,44Z" transform="translate(1823.787 2149.45)" :fill="fill"/>
            <path id="Caminho_2164" data-name="Caminho 2164" d="M49.242,47.393a.848.848,0,1,1-.848.848.851.851,0,0,1,.848-.848m0-3.393a4.242,4.242,0,1,0,4.242,4.242A4.243,4.243,0,0,0,49.242,44Z" transform="translate(1821.815 2149.45)" :fill="fill"/>
            <path id="Caminho_2165" data-name="Caminho 2165" d="M62.242,47.393a.848.848,0,1,1-.848.848.851.851,0,0,1,.848-.848m0-3.393a4.242,4.242,0,1,0,4.242,4.242A4.243,4.243,0,0,0,62.242,44Z" transform="translate(1819.844 2149.45)" :fill="fill"/>
            <path id="Caminho_2166" data-name="Caminho 2166" d="M36.242,59.393a.848.848,0,1,1-.848.848.851.851,0,0,1,.848-.848m0-3.393a4.242,4.242,0,1,0,4.242,4.242A4.243,4.243,0,0,0,36.242,56Z" transform="translate(1823.787 2147.631)" :fill="fill"/>
            <path id="Caminho_2167" data-name="Caminho 2167" d="M49.242,59.393a.848.848,0,1,1-.848.848.851.851,0,0,1,.848-.848m0-3.393a4.242,4.242,0,1,0,4.242,4.242A4.243,4.243,0,0,0,49.242,56Z" transform="translate(1821.815 2147.631)" :fill="fill"/>
            <path id="Caminho_2168" data-name="Caminho 2168" d="M62.242,59.393a.848.848,0,1,1-.848.848.851.851,0,0,1,.848-.848m0-3.393a4.242,4.242,0,1,0,4.242,4.242A4.243,4.243,0,0,0,62.242,56Z" transform="translate(1819.844 2147.631)" :fill="fill"/>
            <path id="Caminho_2169" data-name="Caminho 2169" d="M36.242,71.393a.848.848,0,1,1-.848.848.851.851,0,0,1,.848-.848m0-3.393a4.242,4.242,0,1,0,4.242,4.242A4.243,4.243,0,0,0,36.242,68Z" transform="translate(1823.787 2145.811)" :fill="fill"/>
            <path id="Caminho_2170" data-name="Caminho 2170" d="M49.242,71.393a.848.848,0,1,1-.848.848.851.851,0,0,1,.848-.848m0-3.393a4.242,4.242,0,1,0,4.242,4.242A4.243,4.243,0,0,0,49.242,68Z" transform="translate(1821.815 2145.811)" :fill="fill"/>
            <path id="Caminho_2171" data-name="Caminho 2171" d="M62.242,71.393a.848.848,0,1,1-.848.848.851.851,0,0,1,.848-.848m0-3.393a4.242,4.242,0,1,0,4.242,4.242A4.243,4.243,0,0,0,62.242,68Z" transform="translate(1819.844 2145.811)" :fill="fill"/>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'IconCalculator.vue',
    props: {
        fill: {
            type: String,
            default: '#00305a'
        }
    }
}
</script>